import axios from "axios";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { getProductsByFilter } from "@storefront/core/data-resolver/products";
const state = () => ({
  serie: [],
});

const actions = {
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadSerie({ commit }, { serie_id }) {
    const filter = 'serie:{eq:"' + serie_id + '"}';
    const page = await getProductsByFilter(filter).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      commit("setSerie", page.items);
    }
  },

  async getBlogsBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    const query =
      " { blogPosts(filter:" +
      ' { title: { like: "%' +
      filter +
      '%" } } ' +
      "pageSize: 5)" +
      "{ items { content title } total_count total_pages }}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getBlogsBySearchPreview", "blogs", e)();
      throw e;
    });
    return retval.data.data.blogPosts;
  },

  async getCategoriesBySearchPreview(_, { filter, signal }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    Logger.debug('getCategoriesBySearchPreview', 'index', filter)();
    const query =
      "{categories(search:" +
      JSON.stringify(
        filter
          ?.replace(/\s+/g, " ")
          ?.trim()
          ?.toLowerCase() || ''
      ) +
      "){items{name url_key url_path level path image product_count children{name image description}}}}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      signal: signal,
    }).catch((e) => {
      Logger.error("getBlogsBySearchPreview", "blogs", e)();
      throw e;
    });
    return retval.data.data.categories;
  },
};

const mutations = {
  /**
   * set list page
   * @param {object} state
   * @param {object} payload
   */
  setSerie(state, payload) {
    state.serie = payload;
  },
};

const getters = {
  getSerie: (state) => state.serie,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
