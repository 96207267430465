import axios from "axios";
// import store from "@/store";
import config from "@storefront/core/lib/utils/config";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { loadBlogsPerCategory } from "@storefront/core/data-resolver/blog";
import { getProductByCategoryId } from "@storefront/core/data-resolver/products";

const state = () => ({
  blogs: [],
  specialSelectedProducts: [],
  latestTrendsProducts: [],
  homeBestSellersProducts: [],
  homeNewSofas: [],
  kiyohRatings: null,
});

const actions = {
  /**
   * load Blog by Identifier
   * @param {json} identifier
   */
  async loadHomeBlogs({ commit }, { category_id }) {
    const page = await loadBlogsPerCategory(category_id).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    // Logger.debug("page", "blog store actions", page)();
    if (page != null) {
      commit("setBlogs", page.items);
    }
  },

  async loadSpecialSelectedProducts({ commit }) {
    const products = await getProductByCategoryId(
      config.categories.specially_selected,
      1
    );
    commit("setSpecialSelectedProducts", (products && products.items) ? products.items : []);
  },
  async loadLatestTrendsProducts({ commit }) {
    const products = await getProductByCategoryId(
      config.categories.last_trends,
      1
    );
    commit(
      "setLatestTrendsProducts",
      products && products.items ? products.items : []
    );
  },
  async loadHomeBestSellers({ commit }) {
    const products = await getProductByCategoryId(
      config.categories.best_seller,
      1
    );
    commit(
      "setHomeBestSellers",
      products && products.items ? products.items : []
    );
  },
  async loadHomeNewSofas({ commit }) {
    const products = await getProductByCategoryId(
      config.categories.new_sofas,
      1
    );
    commit("setHomeNewSofas", products && products.items ? products.items : []);
  },
  async loadKiyohRatings({ dispatch, commit }) {
    const retval = await dispatch("getKiyohRatings");
    if (retval !== false) {
      commit("setKiyohRatings", retval);
    }
  },

  async getKiyohRatings() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };

    const query =
      '{kiyoh(filter:{rating:{from:"5"}}sort:{rating:ASC}pageSize:2 currentPage:1){location_id location_name average_rating number_reviews percentage_recommendation last_12_month_average_rating last_12_month_number_reviews reviews{author_name author_location rating date content_groups{group content{type label value}}}}}';

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadKiyohRatings", "store home", e)();
      throw e;
    });
    if (retval.data.data.kiyoh != null) {
      return retval.data.data.kiyoh;
    } else {
      return false;
    }
  },
};

const mutations = {
  /**
   * set list page
   * @param {object} state
   * @param {object} payload
   */
  setBlogs(state, payload) {
    state.blogs = payload;
  },
  setSpecialSelectedProducts(state, payload) {
    state.specialSelectedProducts = payload;
  },
  setLatestTrendsProducts(state, payload) {
    state.latestTrendsProducts = payload;
  },
  setHomeBestSellers(state, payload) {
    state.homeBestSellersProducts = payload;
  },
  setHomeNewSofas(state, payload) {
    state.homeNewSofas = payload;
  },
  setKiyohRatings(state, payload) {
    state.kiyohRatings = payload;
  },
};

const getters = {
  getHomeBlogs: (state) => state.blogs,
  getSpecialSelectedProducts: (state) => state.specialSelectedProducts,
  getLatestTrendsProducts: (state) => state.latestTrendsProducts,
  getHomeBestSellerProducts: (state) => state.homeBestSellersProducts,
  getHomeNewSofas: (state) => state.homeNewSofas,
  getKiyohRatings: (state) => state.kiyohRatings,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
