import axios from 'axios';
import config from '@config';
import { Logger } from '@storefront/core/lib/logger';

import { getCurrentLanguage } from '@storefront/core/i18n';

const state = () => ({
  blogCategories: [],
  filteredBlogs: [],
  current: null, // shown product
  list: [],
  currentPageNr: 1,
  currentPage: [],
  totalPages: 1,
  totalBlogs: 0,
  blogsPerPage: 9,
  customerServiceList: [],
  filteredCustomerServiceBlogs: [],
  totalCustomerServicePages: 1,
  currentCustomerServicePageNr: 1,
  totalCustomerServiceBlogs: 0,
  customerServiceBlogsPerPage: 9,
  customerServiceCategories: [],
  customerServiceCategoryIdList: [],
  blogCategoryIdList: [],
});

const actions = {
  /**
   *
   * load blogs
   */
  async load({ commit, getters, dispatch }) {
    await dispatch('loadBlogCategories');
    const currentPage = 1;
    const page = await loadBlogList(
      currentPage,
      getters["getBlogsPerPage"],
      getters['getBlogCategoryIdList']
    ).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      if (page.items != null) {
        commit("setTotalPages", page.total_pages);
        commit("setTotalBlogs", page.total_count);
        const obj = { page: currentPage, items: page.items };
        commit("setListPage", obj);
        commit("setCurrentPage", page.items);
      }
    }
  },
  async loadCustomerService({ commit, getters, dispatch }) {
    await dispatch('loadBlogCategories');
    const currentPage = 1;
    const page = await loadCustomerServiceList(currentPage,
      getters["getCustomerServiceBlogsPerPage"],
      getters['getCustomerServiceCategoryIdList']
    ).catch((e) => {
      Logger.error("load", "blog store actions", e)();
      throw e;
    });
    if (page != null) {
      if (page.items != null) {
        commit("setTotalCustomerServicePages", page.total_pages);
        commit("setTotalCustomerServiceBlogs", page.total_count);
        const obj = { page: currentPage, items: page.items };
        commit("setCustomerServiceListPage", obj);
        commit("setCurrentCustomerServicePage", page.items);
      }
    }
  },
  async blogCategories() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang['storeview'];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };

    const query =
      '{ blogCategories { items { category_id identifier posts_count title } total_count } }';

    const retval = await axios({
      url: config.shop.graphQLURL + '?query=' + encodeURI(query),
      method: 'GET',
      headers: headers,
      //data: { query: query },
    }).catch((e) => {
      Logger.error('blog', 'loadBlogCategories', e)();
      console.log(e);
      throw e;
    });
    if (retval.data.data.blogCategories != null) {
      return retval.data.data.blogCategories;
    } else {
      return false;
    }
  },
  async blogsByFilter({ getters }, { categoryId, searchVal, currentPage, pageSize, customerService = false }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang['storeview'];
    const csCats = getters['getCustomerServiceCategoryIdList'];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };

    let filter = ' filter: {';

    if (categoryId) {
      filter += ' category_id: { eq : "' + categoryId + '" } ';
    }
    if (!categoryId && customerService) {
      filter += ' category_id: {in: ' + JSON.stringify(csCats) + ' } ';
    } else if (!categoryId && !customerService) {
      filter += ' category_id: {nin: ' + JSON.stringify(csCats) + ' } ';
    }

    if (searchVal) {
      filter += ' search: { eq : "' + searchVal + '" } ';
      // filter += ' title: { like : "%' + searchVal + '%" } ';
    }

    filter += '} ';

    const query =
      '{ blogPosts( pageSize: ' +
      pageSize +
      ' currentPage: ' +
      currentPage +
      filter +
      ' ) { total_count total_pages items { identifier categories { title category_url_path } post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } }  }';

    const retval = await axios({
      url: config.shop.graphQLURL + '?query=' + encodeURI(query),
      method: 'GET',
      headers: headers,
      //data: { query: query },
    }).catch((e) => {
      Logger.error('blog', 'blogsByFilter', e)();
      console.log(e);
      throw e;
    });

    if (retval.data.data.blogPosts != null) {
      return retval.data.data.blogPosts;
    } else {
      return false;
    }
  },
  async loadBlogCategories({ dispatch, commit }) {
    const retval = await dispatch('blogCategories');
    var categories = { cs: [], b: [] };
    commit('resetBlogCategoryIdList');
    commit('resetCustomerServiceCategoryIdList');

    if (retval != false) {
      retval.items.forEach((category) => {
        if (/klantenservice/i.test(category.title)) {
          commit('setCustomerServiceCategoryIdList', category.category_id.toString())
          categories.cs.push(category);
        } else {
          categories.b.push(category);
          commit('setBlogCategoryIdList', category.category_id.toString())
        }
      }
      );
      commit('setBlogCategories', categories.b);
      commit('setCustomerServiceCategories', categories.cs)
    }
  },
  async loadBlogsByFilter(
    { dispatch, commit, rootGetters },
    { categoryId, searchVal }
  ) {
    const pageSize = rootGetters['blog/getBlogsPerPage'];
    const retval = await dispatch('blogsByFilter', {
      categoryId,
      searchVal,
      pageSize,
      currentPage: 1,
    });

    if (retval != false) {
      commit('resetFilteredBlogs');
      commit('setFilteredBlogs', retval.items);
      const totalPages = retval.total_pages;
      if (totalPages > 1) {
        for (let page = 2; page <= totalPages; page++) {
          const retval1 = await dispatch('blogsByFilter', {
            categoryId,
            searchVal,
            pageSize,
            currentPage: page,
          });
          commit('setFilteredBlogs', retval1.items);
        }
      }
    }
  },
  async loadCustomerServiceBlogsByFilter(
    { dispatch, commit, rootGetters },
    { categoryId, searchVal }
  ) {
    const pageSize = rootGetters['blog/getBlogsPerPage'];
    const retval = await dispatch('blogsByFilter', {
      categoryId,
      searchVal,
      pageSize,
      currentPage: 1,
      customerService: true,
    });

    if (retval != false) {
      commit('resetFilteredCustomerServiceBlogs');
      commit('setFilteredCustomerServiceBlogs', retval.items);
      const totalPages = retval.total_pages;
      if (totalPages > 1) {
        for (let page = 2; page <= totalPages; page++) {
          const retval1 = await dispatch('blogsByFilter', {
            categoryId,
            searchVal,
            pageSize,
            currentPage: page,
            customerService: true,
          });
          commit('setFilteredCustomerServiceBlogs', retval1.items);
        }
      }
    }
  },
};

const mutations = {
  /**
 * set list page
 * @param {object} state
 * @param {object} payload
 */
  setListPage(state, payload) {
    state.list.push(payload);
  },
  /**
   * set total pages
   * @param {object} state
   * @param {integer} payload
   */
  setTotalPages(state, payload) {
    state.totalPages = payload;
  },
  /**
   * set blogs per page
   * @param {object} state
   * @param {integer} payload
   */

  setBlogsPerPage(state, payload) {
    state.blogsPerPage = payload;
  },
  /**
   * set set total blogs
   * @param {object} state
   * @param {integer} payload
   */

  setTotalBlogs(state, payload) {
    state.totalBlogs = payload;
  },
  /**
   * set current page
   * @param {object} state
   * @param {array} payload
   */
  setCurrentPage(state, payload) {
    state.currentPage = payload;
  },
  /**
   *
   * @param {object} state
   * @param {integer} payload
   */
  setCurrentPageNr(state, payload) {
    if (payload > state.totalPages) {
      if (payload >= 1) {
        state.currentPageNr = payload;
      }
    }
  },
  /**
   *
   * @param {object} state
   * @param {object} payload
   */
  setCurrent(state, payload) {
    state.current = payload;
  },
  /**
   * set blogs per page
   * @param {object} state
   * @param {integer} payload
   */

  setBlogCategories(state, payload) {
    state.blogCategories = payload;
  },
  setFilteredBlogs(state, payload) {
    payload.forEach((element) => {
      state.filteredBlogs.push(element);
    });
  },
  resetFilteredBlogs(state) {
    state.filteredBlogs = [];
  },
  getCustomerServiceList(state, payload) {
    state.customerServiceList = payload;
  },
  setCustomerServiceBlogsPerPage(state, payload) {
    state.customerServiceBlogsPerPage = payload;
  },
  setTotalCustomerServicePages(state, payload) { state.totalCustomerServicePages = payload },
  setTotalCustomerServiceBlogs(state, payload) { state.totalCustomerServiceBlogs = payload },
  setCustomerServiceListPage(state, payload) { state.customerServiceList.push(payload) },
  setCurrentCustomerServicePage(state, payload) {
    if (payload > state.totalCustomerServicePages) {
      if (payload >= 1) {
        state.currentCustomerServicePageNr = payload;
      }
    }
  },
  setCustomerServiceCategories(state, payload) {
    state.customerServiceCategories = payload;
  },
  setCustomerServiceCategoryIdList(state, payload) {
    state.customerServiceCategoryIdList.push(payload);
  },
  resetCustomerServiceCategoryIdList(state) {
    state.customerServiceCategoryIdList = [];
  },
  setBlogCategoryIdList(state, payload) {
    state.blogCategoryIdList.push(payload);
  },
  resetBlogCategoryIdList(state) {
    state.blogCategoryIdList = [];
  },
  setFilteredCustomerServiceBlogs(state, payload) {
    payload.forEach((element) => {
      state.filteredCustomerServiceBlogs.push(element);
    });
  },
  resetFilteredCustomerServiceBlogs(state) {
    state.filteredCustomerServiceBlogs = [];
  },
};

const getters = {
  getBlogsPerPage: (state) => state.blogsPerPage,
  getTotalPages: (state) => state.totalPages,
  getTotalBlogs: (state) => state.totalBlogs,
  getList: (state) => state.list,
  getCurrentPage: (state) => state.currentPage,
  getBreadcrumbsCurrent: (state) => state.current,
  getCurrentPageNr: (state) => state.currentPageNr,
  getBlog: (state) => state.current,
  getBlogCategories: (state) => state.blogCategories,
  getFilteredBlogs: (state) => state.filteredBlogs,
  getCustomerServiceList: (state) => state.customerServiceList,
  getFilteredCustomerServiceBlogs: (state) => state.filteredCustomerServiceBlogs,
  getTotalCustomerServicePages: (state) => state.totalCustomerServicePages,
  getTotalCustomerServiceBlogs: (state) => state.getTotalCustomerServiceBlogs,
  getCustomerServiceCurrentPageNr: (state) => state.currentCustomerServicePageNr,
  getCustomerServiceBlogsPerPage: (state) => state.customerServiceBlogsPerPage,
  getCustomerServiceCategories: (state) => state.customerServiceCategories,
  getCustomerServiceCategoryIdList: (state) => state.customerServiceCategoryIdList,
  getBlogCategoryIdList: (state) => state.blogCategoryIdList,
};

/**
* Load the bloglist from magento graphql with pagination
* @param {integer} currentPage
* @param {integer} pageSize
* @returns array of blog posts
*/
async function loadBlogList(currentPage, pageSize, blogCategories) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ blogPosts(filter: {category_id: {in: " + JSON.stringify(blogCategories) + "}} currentPage: " +
    currentPage +
    ", pageSize: " +
    pageSize +
    ', sort: ["DESC"]) { total_count total_pages items { identifier categories { title category_url_path } post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } } }';

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.blogPosts;
}
async function loadCustomerServiceList(currentPage, pageSize, blogCategories) {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ blogPosts(filter: {category_id: {in: " + JSON.stringify(blogCategories) + "}} currentPage: " +
    currentPage +
    ", pageSize: " +
    pageSize +
    ', sort: ["DESC"]) { total_count total_pages items { identifier categories { title category_url_path } post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } } }';

  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadContentBlocks", "data-resolver cms", e)();
    throw e;
  });
  return retval.data.data.blogPosts;
}
// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
