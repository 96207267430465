import axios from 'axios';
import config from '@config';
import store from "@/store";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";

const findGuestOrderQuery = '{\
  checkGuestReturnAvailable(\
    incrementId: "$incrementId"\
    returnToken: "$token"\
  ) {\
    order {\
      id\
      order_date\
      carrier\
      number\
      status\
      total {\
        base_grand_total {\
          value\
        }\
        discounts {\
          label\
          amount {\
            value\
          }\
          __typename\
        }\
        shipping_handling {\
          amount_including_tax {\
            value\
          }\
        }\
      }\
      billing_address {\
        firstname\
        lastname\
        company\
        vat_id\
        street\
        postcode\
        city\
        country_code\
      }\
      shipping_address {\
        firstname\
        lastname\
        company\
        vat_id\
        street\
        postcode\
        city\
        country_code\
      }\
      payment_methods {\
        name\
        type\
        additional_data {\
          name\
          value\
        }\
      }\
      items {\
        id\
        product_sku\
        product_name\
        product_url_key\
        product_sale_price {\
          value\
        }\
        quantity_ordered\
        selected_options {\
          label\
          value\
        }\
        entered_options {\
          label\
          value\
        }\
        quantity_available\
      }\
    }\
    reasons {\
      id\
      label\
      actions {\
        id\
        label\
      }\
    }\
    settings {\
      allow_comments\
      allow_uploads\
      enable_terms\
      terms_text\
    }\
  }\
}';

const findUserOrderQuery = '{\
  checkReturnAvailable(\
    incrementId: "$incrementId"\
  ) {\
    order {\
      id\
      order_date\
      carrier\
      number\
      status\
      total {\
        base_grand_total {\
          value\
        }\
        discounts {\
          label\
          amount {\
            value\
          }\
          __typename\
        }\
        shipping_handling {\
          amount_including_tax {\
            value\
          }\
        }\
      }\
      billing_address {\
        firstname\
        lastname\
        company\
        vat_id\
        street\
        postcode\
        city\
        country_code\
      }\
      shipping_address {\
        firstname\
        lastname\
        company\
        vat_id\
        street\
        postcode\
        city\
        country_code\
      }\
      payment_methods {\
        name\
        type\
        additional_data {\
          name\
          value\
        }\
      }\
      items {\
        id\
        product_sku\
        product_name\
        product_url_key\
        product_sale_price {\
          value\
        }\
        quantity_ordered\
        selected_options {\
          label\
          value\
        }\
        entered_options {\
          label\
          value\
        }\
        quantity_available\
      }\
    }\
    reasons {\
      id\
      label\
      actions {\
        id\
        label\
      }\
    }\
    settings {\
      allow_comments\
      allow_uploads\
      enable_terms\
      terms_text\
    }\
  }\
}';

const sendGuestReturnRequestQuery = `mutation rmaRequestMutation($data: RequestGuestReturnInput) {\
    createGuestReturnRequest(input: $data) {\
        $returnData\
    }\
}`;

const sendUserReturnRequestQuery = `mutation rmaRequestMutation($data: RequestReturnInput) {\
    createReturnRequest(input: $data) {\
        $returnData\
    }\
}`;

const getReturnOrderQuery = '{ return(incrementId: "$incrementId") { $returnData } }';
const getReturnByIdQuery = '{ return(id: "$id") { $returnData } }';

const getLabelPaymentQuoteQuery = 'mutation {\
    createReturnLabelQuote(returnId: "$id") {\
        id\
        available_payment_methods {\
            code\
            label\
            additional_fields {\
                code\
                label\
                type\
                options {\
                    value\
                    label\
                }\
            }\
        }\
     }\
 }';
 
const placeReturnLabelOrderQuery = 'mutation ($placeReturnLabelOrderInput: placeReturnLabelOrderInput)  {\
    placeReturnLabelOrder(input: $placeReturnLabelOrderInput) {\
        order {\
            id\
            increment_id\
            hash\
        }\
        redirect_url\
     }\
 }';
 
 
const customerReturnsQuery = '{ customer { \
 returns (\
    $filter\
    $pageSize,\
    $currentPage\
 ) { \
    total_count\
    page_info {\
        page_size\
        current_page\
        total_pages\
    }\
    items {\
        $returnData\
    }\
 } } }';
 
const returnRequestData = 'id\
 request_date\
 status\
 number\
 comment\
 order {\
    number\
 }\
 items {\
    order_item {\
        product_name\
        product_sku\
        product_url_key\
        image {\
            small\
        }\
        selected_options {\
          label\
          value\
        }\
        entered_options {\
          label\
          value\
        }\
    }\
    quantity\
    reason\
    action\
    comment\
    files {\
        name\
        url\
    }\
 }\
 return_label {\
    payment_needed\
    items {\
        tracking_code\
        title\
        label_url\
        tracking_url\
    }\
 }';

export async function requestGuestReturn(orderId, postcode) {
    
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = `mutation { requestGuestReturn (incrementId: "`+orderId+`" postcode: "`+postcode+`") { email_sent } }`;
    
    const response = await axios({
        url: config.shop.graphQLURL,
        method: "POST",
        headers: headers,
        data: { query: query },
    }).catch((e) => {
        Logger.error("requestGuestReturn", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;

}

export async function findGuestOrder(incrementId, token) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = findGuestOrderQuery.replace(/\$incrementId/g, incrementId)
        .replace(/\$token/g, token);
            
    const response = await axios({
        url: config.shop.graphQLURL + '?query=' + query,
        method: "GET",
        headers: headers
    }).catch((e) => {
        Logger.error("requestGuestReturn", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

export async function sendGuestRequest(data) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = sendGuestReturnRequestQuery.replace(/\$returnData/g, returnRequestData);

    const response = await axios({
        url: config.shop.graphQLURL,
        method: "POST",
        headers: headers,
        data: JSON.stringify({
            query,
            variables: {data: data}
        }),
    }).catch((e) => {
        Logger.error("sendGuestRequest", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

export async function getReturnById(id) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = getReturnByIdQuery.replace(/\$returnData/g, returnRequestData)
        .replace(/\$id/, id);
        
    const response = await axios({
        url: config.shop.graphQLURL + '?query=' + query,
        method: "GET",
        headers: headers
    }).catch((e) => {
        Logger.error("getReturnById", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

export async function getReturnLabelPaymentData(id) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = getLabelPaymentQuoteQuery.replace(/\$id/g, id);

    const response = await axios({
        url: config.shop.graphQLURL,
        method: "POST",
        headers: headers,
        data: JSON.stringify({
            query
        }),
    }).catch((e) => {
        Logger.error("getReturnLabelPaymentData", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

export async function sendPaymentRequest(paymentData) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = placeReturnLabelOrderQuery;

    const response = await axios({
        url: config.shop.graphQLURL,
        method: "POST",
        headers: headers,
        data: JSON.stringify({
            query,
            variables: {placeReturnLabelOrderInput: paymentData}
        }),
    }).catch((e) => {
        Logger.error("sendGuestRequest", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

export async function getTestOrder(incrementId) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };
    
    const query = getReturnOrderQuery.replace(/\$returnData/g, returnRequestData)
        .replace(/\$incrementId/, incrementId);
        
    const response = await axios({
        url: config.shop.graphQLURL + '?query=' + query,
        method: "GET",
        headers: headers
    }).catch((e) => {
        Logger.error("sendGuestRequest", "data-resolver rma", e)();
        console.log(e);
        throw e;
    });
    
    return response.data;
}

const state = () => ({
  returnRequests: [],
  lastUserOrder: null,
  lastUserRequest: null
});

const actions = {
    
    async findUserOrder({commit}, { incrementId }) {
        const lang = getCurrentLanguage();
        const storelang = config.languages[lang];
        const storeview = storelang["storeview"];

        if (!store.getters["user/getIsLoggedIn"] != false) {
            throw new Error('User is not logged in');
        }
        
        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + store.getters["user/getUserToken"],
          Store: storeview,
        };
        
        const query = findUserOrderQuery.replace(/\$incrementId/g, incrementId);
                
        const response = await axios({
            url: config.shop.graphQLURL + '?query=' + query,
            method: "GET",
            headers: headers
        }).catch((e) => {
            Logger.error("requestUserReturn", "data-resolver rma", e)();
            console.log(e);
            throw e;
        });
        
        if (typeof response.errors !== 'undefined' && response.errors[0].extensions.category === 'graphql-authorization') {
            this.$router.push({path: '/login/'});
        }

        commit('setLastUserOrder', response.data);
        
        return response.data;
    },
    
    async sendUserRequest({commit}, {data}) {
        const lang = getCurrentLanguage();
        const storelang = config.languages[lang];
        const storeview = storelang["storeview"];
        
        if (!store.getters["user/getIsLoggedIn"] != false) {
            throw new Error('User is not logged in');
        }

        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + store.getters["user/getUserToken"],
          Store: storeview,
        };
        
        const query = sendUserReturnRequestQuery.replace(/\$returnData/g, returnRequestData);

        const response = await axios({
            url: config.shop.graphQLURL,
            method: "POST",
            headers: headers,
            data: JSON.stringify({
                query,
                variables: {data: data}
            }),
        }).catch((e) => {
            Logger.error("sendUserRequest", "data-resolver rma", e)();
            console.log(e);
            throw e;
        });
        
        if (typeof response.errors !== 'undefined' && response.errors[0].extensions.category === 'graphql-authorization') {
            this.$router.push({path: '/login/'});
        }
        
        commit('setLastUserRequest', response.data);

        return response.data;
    },
    
    
    async loadReturnRequests({commit, state}, {filterInput, currentPage, pageSize}) {
        
        const lang = getCurrentLanguage();
        const storelang = config.languages[lang];
        const storeview = storelang["storeview"];
        
        if (!store.getters["user/getIsLoggedIn"] != false) {
            throw new Error('User is not logged in');
        }

        const headers = {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + store.getters["user/getUserToken"],
          Store: storeview,
        };
        
        let filters = '';
        
        if (filterInput && typeof filterInput !== 'undefined' && Object.keys(filterInput).length > 0) {
            filters += 'filter: { ';
            Object.keys(filterInput).forEach((filterField) => {
                if (
                    typeof filterInput[filterField] !== 'undefined' && 
                    filterInput[filterField] !== null && 
                    filterInput[filterField].trim().length > 0
                ) {
                    filters += filterField + ': { match: "' + filterInput[filterField] + '" } ';
                }
            });
            filters += ' } ';
        }
        
        let currentPageInput = 'currentPage: ' + currentPage;
        let pageSizeInput = 'pageSize: ' + pageSize;
        
        let query = customerReturnsQuery.replace(/\$filter/, filters)
            .replace(/\$currentPage/, currentPageInput)
            .replace(/\$pageSize/, pageSizeInput)
            .replace(/\$returnData/g, returnRequestData);
        
        const response = await axios({
            url: config.shop.graphQLURL  + '?query=' + query,
            method: "GET",
            headers: headers
        }).catch((e) => {
            Logger.error("getCustomerReturns", "data-resolver rma", e)();
            console.log(e);
            return state.getters['rma/userRequests'];
        });
        
        if (typeof response.errors !== 'undefined' && response.errors[0].extensions.category === 'graphql-authorization') {
            this.$router.push({path: '/login/'});
        }
        
        commit('setReturnRequests', response.data.data.customer.returns);
        
        return response.data.data.customer.returns; 
    }
};

const mutations = {

    setLastUserRequest(state, payload) {
        state.lastUserRequest = { ...payload };
    },
    setLastUserOrder(state, payload) {
        state.lastUserOrder = { ...payload };
    },
    setReturnRequests(state, payload) {
        state.returnRequests = payload;
    }
};

const getters = {
    userRequests: (state) => state.returnRequests
};

export default {
    namespaced: true,
    state, 
    actions,
    mutations,
    getters
};
